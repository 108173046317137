<template>
  <div>
    <div id="knives-conditions" class="knives-id"></div>
    <div class="knives__collection">
      <div class="container">
        <div class="knives__block-title knives__collection-title">
          {{ $t("knives.collection") }}
        </div>
        <intersect @enter="getAnchor('conditions')" @leave="getAnchor(null)">
          <div class="knives__collection-inner">
            <div class="knives__collection-item">
              <div class="knives__collection-item--box">
                <div class="knives__collection-item--image">
                  <span>1</span>
                  <img src="@/assets/img/knives/collection-1.svg" alt="" />
                </div>
                <div class="knives__collection-item--text">
                  {{ $t("knives.collectionItem1") }}
                </div>
              </div>
            </div>
            <div class="knives__collection-item">
              <div class="knives__collection-item--box">
                <div class="knives__collection-item--image">
                  <span>2</span>
                  <img
                    v-if="$i18n.locale === 'ru'"
                    src="@/assets/img/knives/collection-2.svg"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/img/knives/collection-2-kz.svg"
                    alt=""
                  />
                </div>
                <div class="knives__collection-item--text">
                  {{ $t("knives.collectionItem2") }}
                </div>
              </div>
            </div>
            <div class="knives__collection-item">
              <div class="knives__collection-item--box">
                <div class="knives__collection-item--image">
                  <span>3</span>
                  <img
                    v-if="$i18n.locale === 'ru'"
                    src="@/assets/img/knives/collection-3.svg"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/img/knives/collection-3-kz.svg"
                    alt=""
                  />
                </div>
                <div class="knives__collection-item--text">
                  {{ $t("knives.collectionItem3") }}
                </div>
              </div>
            </div>
          </div>
        </intersect>
        <div
          class="knives__collection-disclaimer"
          v-html="$t('knives.collectionItemSpan')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Intersect from "vue-intersect";
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/knives";
export default {
  name: "KnivesCollection",
  components: {
    Intersect,
  },
  methods: {
    ...mapActions("knivesModule", {
      getAnchor: actionTypes.getAnchor,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
